<template>
    <v-container v-if="!loading && message" class="pa-0">
        <a-alert :message="message" type="info" />
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';

import { MediaModuleSlideCollector } from '@/mixins';

import { AAlert } from '@/components/AAlert';

import type { Video } from '@/types/Video';

const AlertVideosFilledProps = Vue.extend({
    name: 'AlertVideosFilled',
    props: {
        video: {
            type: Object as PropType<Video | null>,
            default(): Video | null {
                return null;
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        AAlert
    }
})
export default class AlertVideosFilled extends mixins(
    AlertVideosFilledProps,
    MediaModuleSlideCollector
) {
    get autofilledMediaFileIds() {
        return JSON.parse(
            this.video?.announcement?.video_stock_media_autofill || '[]'
        );
    }

    get boxes() {
        return this.video?.video_template?.video_boxes || [];
    }

    get totalSlides() {
        return this.boxes[this.boxes.length - 1]?.ui_group || 0;
    }

    get message() {
        const slides = this.getAutoFilledSlides();

        if (slides.length) {
            const tail =
                'chosen by software using your video search phrase; please review.';

            if (slides.length === 1) {
                return `Video for scene ${this.slidesToString(
                    slides
                )} was ${tail}`;
            }

            return `Videos for scenes ${this.slidesToString(
                slides
            )} were ${tail}`;
        }

        return null;
    }

    slidesToString(slides: number[]) {
        if (slides.length > 1) {
            const anding = slides.splice(-2);

            return [
                slides.map(s => this.linkify(s)).join(', '),
                anding.map(s => this.linkify(s)).join(' and ')
            ]
                .filter(Boolean)
                .join(', ');
        }

        return this.linkify(slides[0]);
    }

    linkify(slide: number) {
        return `<a data-slide="${slide}">${slide}</a>`;
    }

    getAutoFilledSlides() {
        const autoFilledSlides = [];
        const ids = this.autofilledMediaFileIds;

        if (ids.length) {
            for (let slide = 0; slide <= this.totalSlides; slide++) {
                if (this.isAutoFilledSlide(slide, ids)) {
                    autoFilledSlides.push(slide + 1);
                }
            }
        }

        return autoFilledSlides;
    }

    isAutoFilledSlide(slide: number, autofilledIds: number[]) {
        const boxes = this.getVisibleBoxesForSlide(slide);

        return boxes.some(box => {
            if (box.video_media_box) {
                const id = box.video_media_box.media_resource.media_file_id;

                if (id && autofilledIds.includes(id)) {
                    return true;
                }
            }

            return false;
        });
    }
}
</script>
