<template>
    <v-container class="amp-module-page wide">
        <a-form ref="form" auto-submit @auto-submit="setData">
            <v-card
                class="pa-4"
                :class="{
                    'pa-5 px-6': $vuetify.breakpoint.mdAndUp
                }"
            >
                <v-row v-if="hasPendingAutofill">
                    <v-col>
                        <a-alert type="warning">
                            <v-progress-circular
                                indeterminate
                                size="14"
                                width="2"
                                class="mx-2"
                            />
                            The auto-selecting videos is still in progress ...
                        </a-alert>
                    </v-col>
                </v-row>
                <v-row v-if="hasPublishIssues">
                    <v-col>
                        <a-alert
                            v-for="(issue, i) in publishIssues"
                            :key="i"
                            :message="issue.message"
                        />
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col>
                        <a-text-input
                            ref="title"
                            v-model="video.title"
                            :loading="isLoading"
                            label="Title"
                            rules="required|max:100|youtube_video_title"
                            rows="1"
                            auto-grow
                            textarea
                        />
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col>
                        <a-content-editor
                            ref="summary"
                            v-model="video.description"
                            :loading="isLoading"
                            :no-first-person="false"
                            label="Description"
                            rules="required|words:0,45"
                            :counter-value="getWordsCounter(45)"
                            one-line
                        />
                    </v-col>
                </v-row>
                <v-row v-if="$vuetify.breakpoint.smAndDown">
                    <v-col class="py-0 col-12">
                        <a-slideshow
                            ref="slideshow"
                            label="Preview"
                            :template="video.video_template"
                            :active="slide"
                            delimiter-icon="film"
                            link-entity="scene"
                            allow-stock-video-search
                            class="pb-4"
                            @slide="sync"
                            @zoom="handleFullScreen"
                        />

                        <alert-videos-filled
                            :loading="isLoading"
                            :video="video"
                            @click.native="navigateToSlide"
                        />

                        <amp-validation
                            :input="$refs.slideshow"
                            parse
                            limit="5"
                            class="pointing-validation mt-4"
                            @click.native="navigateToSlide"
                        />
                        <v-container class="pa-0">
                            <v-row>
                                <v-col class="text-right">
                                    <v-btn
                                        :disabled="isLoading"
                                        text
                                        :large="$vuetify.breakpoint.mdAndUp"
                                        :block="$vuetify.breakpoint.smAndDown"
                                        @click="changeTemplate"
                                    >
                                        <v-icon left>folder-open</v-icon>
                                        Change Template
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
                <v-row class="video-container">
                    <v-col class="py-0 col-md-6 col-lg-6 col-sm-12">
                        <v-container v-if="canHaveLogo" class="pa-0">
                            <v-row>
                                <v-col>
                                    <a-toggle
                                        v-model="useLogo"
                                        :class="{
                                            'mt-0': $vuetify.breakpoint.mdAndUp,
                                            'mt-6': $vuetify.breakpoint
                                                .smAndDown
                                        }"
                                        label="Display company logo overlay in video"
                                        :disabled="!companyHasLogo"
                                        @change="toggleLogo"
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                        <media-form
                            ref="mediaForm"
                            :template="video.video_template"
                            entity="scene"
                            entity-icon="film"
                            class="pa-0"
                            @activate="sync"
                        />
                        <v-container class="pa-0 pt-6">
                            <v-row dense>
                                <v-col class="flex-grow-1">
                                    <a-select-input
                                        v-model="video.video_voice_id"
                                        :loading="isLoading"
                                        label="Select voice"
                                        :items="voices"
                                        item-text="voice_name"
                                        item-value="id"
                                    />
                                </v-col>
                                <v-col class="pl-4 pt-3 flex-grow-0">
                                    <audio-player-chip :src="activeVoiceSrc" />
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col class="flex-grow-1">
                                    <a-select-input
                                        v-model="video.video_audio_clip_id"
                                        :loading="isLoading"
                                        label="Select Audio Clip"
                                        :items="clips"
                                        item-text="name"
                                        item-value="id"
                                    />
                                </v-col>
                                <v-col class="pl-4 pt-3 flex-grow-0">
                                    <audio-player-chip :src="activeClipSrc" />
                                </v-col>
                            </v-row>
                            <a-combobox
                                v-model="video.tags_array"
                                :loading="isLoading"
                                label="Tags"
                                rules="required|max_items:10"
                                limited
                                limited-hint="No more than 10 tags are permitted"
                                chips
                                deletable-chips
                                clearable
                                multiple
                                append-icon
                                hint="Hit enter to add a tag after typing"
                                persistent-hint
                            />
                            <a-toggle
                                v-model="video.add_ident"
                                :loading="isLoading"
                                label="Show UBC News Intro"
                                class="pt-8 ma-0"
                            />
                        </v-container>
                    </v-col>
                    <v-col
                        v-if="$vuetify.breakpoint.mdAndUp"
                        class="py-0 col-md-6 col-lg-6"
                    >
                        <sticky-media
                            relative-element-selector=".video-container"
                            :offset="{ top: 90, bottom: 90 }"
                            :enabled="
                                !isFullScreen && !$vuetify.breakpoint.mobile
                            "
                        >
                            <a-slideshow
                                ref="slideshow"
                                label="Preview"
                                :template="video.video_template"
                                :active="slide"
                                delimiter-icon="film"
                                link-entity="scene"
                                class="pb-4"
                                allow-stock-video-search
                                @slide="sync"
                                @zoom="handleFullScreen"
                            />

                            <alert-videos-filled
                                :loading="isLoading"
                                :video="video"
                                @click.native="navigateToSlide"
                            />

                            <amp-validation
                                :input="$refs.slideshow"
                                parse
                                limit="5"
                                class="pointing-validation mt-4"
                                @click.native="navigateToSlide"
                            />
                            <v-container class="pa-0">
                                <v-row>
                                    <v-col class="text-right">
                                        <v-btn
                                            :disabled="isLoading"
                                            text
                                            :large="$vuetify.breakpoint.mdAndUp"
                                            :block="
                                                $vuetify.breakpoint.smAndDown
                                            "
                                            @click="changeTemplate"
                                        >
                                            <v-icon left>folder-open</v-icon>
                                            Change Template
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </sticky-media>
                    </v-col>
                </v-row>
            </v-card>
        </a-form>
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';

import { Endpoint, MediaModuleEndpoint, WordsCounter } from '@/mixins';
import { InjectReactive, ProvideReactive, Watch } from '@/utils/decorators';

import { AAlert } from '@/components/AAlert';
import { AForm } from '@/components/AForm';
import { ASlideshow, MediaForm, StickyMedia } from '@/components/ASlideshow';
import { AudioPlayerChip } from '@/components/AudioPlayerChip';

import { ACombobox } from '@/components/AForm/Inputs/ACombobox';
import { AContentEditor } from '@/components/AForm/Inputs/AContentEditor';
import { ASelectInput } from '@/components/AForm/Inputs/ASelectInput';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';
import { AToggle } from '@/components/AForm/Inputs/AToggle';

import { AlertVideosFilled } from '@/components/VideoAutofill';

import type { NavigationGuardNext, Route } from 'vue-router';

import {
    AmpRow,
    AmpRowFirst,
    AmpRowLast,
    AmpValidation
} from '@/components/AmpModule/AmpPage';

import type { AmpModules, Announcement } from '@/types/Announcement';
import type { MediaFile } from '@/types/MediaFile';
import type { ModuleLink } from '@/types/ModuleLink';
import type { VideoBox, VideoPr, VideoVoice } from '@/types/Video';

Component.registerHooks(['beforeRouteLeave']);

@Component({
    components: {
        AAlert,
        AudioPlayerChip,
        AmpRow,
        AmpRowFirst,
        AmpRowLast,
        AmpValidation,
        MediaForm,
        AForm,
        ATextInput,
        AContentEditor,
        ASelectInput,
        ACombobox,
        AToggle,
        ASlideshow,
        StickyMedia,
        AlertVideosFilled
    }
})
export default class Video extends mixins(
    Endpoint,
    MediaModuleEndpoint,
    WordsCounter
) {
    $refs!: {
        form: InstanceType<typeof AForm>;
        mediaForm: InstanceType<typeof MediaForm>;
        title: InstanceType<typeof ATextInput>;
        summary: InstanceType<typeof ATextInput>;
        slideshow: InstanceType<typeof ASlideshow>;
    };

    @InjectReactive({
        from: 'modules',
        default() {
            return null;
        }
    })
    modules!: AmpModules;

    @InjectReactive({
        from: 'announcement',
        default() {
            return {
                video_stock_media_autofill: null
            };
        }
    })
    announcement!: Partial<Announcement>;

    @Watch('modules')
    onModulesChanged() {
        this.onMounted();
    }

    @Watch('slide')
    onSlideChange() {
        this.syncForm(this.slide);
    }

    @ProvideReactive()
    get user_id() {
        return this.video.user_id;
    }

    slide = 0;

    video: Partial<VideoPr> = {};

    endpoint = '/video_prs/edit';

    isSaving = false;

    isFullScreen = false;

    canGeneratePreview = true;

    useLogo = false;

    link: ModuleLink[] = [
        {
            type: 'primary',
            label: 'Review',
            to: this.reviewLink
        }
    ];

    get moduleId() {
        return this.modules?.video_pr_id;
    }

    get announcementId() {
        return this.$route.params.announcementId;
    }

    get sourceUrl() {
        return [this.endpoint, this.moduleId].join('/');
    }

    get hasVoices() {
        return this.voices.length > 1;
    }

    get voices() {
        return this.addNoVoiceEntry(
            this.video.video_template?.video_voices || []
        );
    }

    get activeVoiceSrc() {
        const active = this.voices.find(
            voice => voice.id === this.video.video_voice_id
        );

        if (active) {
            return active.route;
        }

        return '';
    }

    get hasAudioClips() {
        return this.clips.length > 0;
    }

    get clips() {
        return (this.video.video_template?.video_audio_clips || []).map(
            (clip, i) => {
                return {
                    ...clip,
                    name: `Audio Clip ${i + 1}`
                };
            }
        );
    }

    get activeClipSrc() {
        const active = this.clips.find(
            clip => clip.id === this.video.video_audio_clip_id
        );

        if (active) {
            return active.route;
        }

        return '';
    }

    get hasPublishIssues() {
        return this.publishIssues.length > 0;
    }

    get publishIssues() {
        return this.$store.getters['broadcast/subscribe'](
            `${this.announcementId}-publish-video`
        );
    }

    get companyHasLogo() {
        return Boolean(
            this.video.announcement?.company?.media_resources[0]?.media_file.id
        );
    }

    get canHaveLogo() {
        return this.video.video_template?.video_boxes.some(
            box => box.video_media_box?.is_logo_box
        );
    }

    get hasLogoApplied() {
        return this.video.video_template?.video_boxes.some(box => {
            if (box.video_media_box?.is_logo_box) {
                const [blankMediaFileId]: number[] = JSON.parse(
                    box.video_media_box?.media_file_ids || '[]'
                );

                if (blankMediaFileId) {
                    return (
                        box.video_media_box.media_resource.media_file_id !==
                        blankMediaFileId
                    );
                }
            }

            return false;
        });
    }

    get reviewLink() {
        return `/announcements/review/${this.announcementId}/video`;
    }

    get hasPendingAutofill() {
        return this.announcement.video_stock_media_autofill === '[]';
    }

    get isValid() {
        return this.$refs.form.isValidated ? this.$refs.form.isValid : true;
    }

    onMounted() {
        if (this.moduleId) {
            this.setPrePublishHook();

            this.load();
        } else {
            this.askToCreate();
        }
    }

    setPrePublishHook(isSet = true) {
        this.$emit('pre-publish', isSet ? this.prePublish.bind(this) : null);
    }

    askToCreate() {
        if (this.hasPendingAutofill) {
            // refresh in 10 seconds
            setTimeout(() => this.$emit('reload'), 10000);
        } else if (this.modules && !this.moduleId) {
            this.$emit('create', this.endpoint);
        }
    }

    onData(data: { video: VideoPr }) {
        if (data.video) {
            this.video = data.video;

            this.protectRoute();

            this.toggleLogoInUse();

            this.emitLinks();

            return this.setSaved();
        } else {
            this.review();
        }
    }

    beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
        this.setPrePublishHook(false);

        if (this.$refs.form.isDirty) {
            return this.save(false).then(() => this.canLeaveRoute(to, next));
        } else {
            return this.canLeaveRoute(to, next, true);
        }
    }

    async canLeaveRoute(
        to: Route,
        next: NavigationGuardNext,
        needsValidation = false
    ) {
        if (this.video.is_editable && to.path === this.reviewLink) {
            if (needsValidation) {
                await this.$refs.form.validate();
            }

            if (!this.isValid) {
                this.notifyInvalid();

                this.emitLinks(true);
                // no reason to switch to preview
                return next(false);
            }
        }

        return next();
    }

    async save(foreground = true) {
        this.setSaving();

        return this.setData()
            .then(async () => {
                await this.setSaved();

                if (foreground) {
                    this.onSave();
                }
            })
            .catch(() => {
                if (foreground) {
                    this.notifyError();
                }
            })
            .finally(this.setSaving.bind(this, false));
    }

    async setData() {
        this.canGeneratePreview = await this.revalidate();

        return this.$http
            .post(this.sourceUrl, this.getDataToSave())
            .then(({ data }) => {
                // update local instance with computed props, if any
                if (data.video) {
                    this.video = data.video;
                    // if video became not-editable
                    this.protectRoute();
                }

                return data;
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }

                throw error;
            });
    }

    getDataToSave() {
        return {
            id: this.video.id,
            video_template_id: this.video.video_template_id,
            video_audio_clip_id: this.video.video_audio_clip_id,
            video_voice_id: this.video.video_voice_id,
            add_ident: this.video.add_ident,
            tags: this.video.tags_array,
            title: this.video.title,
            description: this.video.description,
            media_resources: this.collectMediaResources(
                this.video.video_template
            ),
            video_captions: this.collectVideoCaptions(
                this.video.video_template
            ),
            generate_preview: this.canGeneratePreview
        };
    }

    setSaving(isSaving = true) {
        this.isSaving = isSaving;
    }

    async setSaved() {
        return this.$refs.form.reset();
    }

    navigateToSlide(e: PointerEvent) {
        const slide = (e.target as HTMLElement).dataset.slide;

        if (slide) {
            this.sync(Number(slide));
        }
    }

    sync(slide: number) {
        this.slide = slide;
    }

    syncForm(slide: number) {
        this.$nextTick(() => {
            this.$refs.mediaForm.scrollToGroup(slide);
        });
    }

    addNoVoiceEntry(voices: Partial<VideoVoice>[]) {
        voices.unshift({
            id: null,
            voice_name: 'No Voiceover',
            route: ''
        });

        return voices;
    }

    async onSave() {
        const isValid = await this.revalidate();

        if (isValid) {
            this.review();
        }
    }

    async revalidate() {
        // reset existing errors
        this.$store.dispatch(
            'broadcast/reset',
            `${this.announcementId}-publish-video`
        );

        const isValid = await this.$refs.form.validate();

        if (!isValid) {
            this.notifyInvalid();
        }

        return isValid;
    }

    review() {
        this.$router.push(`/announcements/review/${this.announcementId}/video`);
    }

    async changeTemplate() {
        if (this.$refs.form?.isDirty) {
            await this.save(false);
        }

        return this.$router.push(
            `/announcements/edit/${this.announcementId}/video/from-template`
        );
    }

    handleFullScreen(isFullScreen: boolean) {
        this.isFullScreen = isFullScreen;
    }

    protectRoute() {
        if (!this.video.is_editable) {
            this.review();
        }
    }

    emitLinks(reset = false) {
        if (reset) {
            this.$emit('links', []);
        }

        this.$nextTick(() => {
            this.$emit('links', this.link);
        });
    }

    prePublish() {
        if (!this.$refs.form?.isDirty) {
            return this.revalidate();
        }

        this.setSaving();

        return this.setData()
            .then(() => {
                return this.revalidate();
            })
            .catch(() => {
                this.notifyError();

                return false;
            })
            .finally(this.setSaving.bind(this, false));
    }

    notifyInvalid() {
        this.$store.dispatch(
            'notification/warn',
            'Video saved successfully. Please check the form for errors.'
        );
    }

    notifyError() {
        this.$store.dispatch(
            'notification/error',
            'Unable to save Video. Please check the form for errors.'
        );
    }

    toggleLogo(isOn = false) {
        const logoBoxes = this.video.video_template?.video_boxes.filter(
            box => box.video_media_box?.is_logo_box
        );

        logoBoxes?.forEach(box => {
            if (box.video_media_box) {
                const [blankMediaFileId]: number[] = JSON.parse(
                    box.video_media_box?.media_file_ids || '[]'
                );

                if (blankMediaFileId) {
                    this.setBoxMediaFile(
                        box,
                        box.video_media_box?.media_files.find(file =>
                            isOn
                                ? file.id !== blankMediaFileId
                                : file.id === blankMediaFileId
                        )
                    );
                }
            }
        });
    }

    toggleLogoInUse() {
        if (this.hasLogoApplied) {
            this.useLogo = true;
        }
    }

    setBoxMediaFile(box: VideoBox, file?: MediaFile) {
        if (box.video_media_box && file) {
            box.video_media_box.media_resource.media_file = file;
            // validation needs this
            box.video_media_box.media_resource.media_file_id = file.id;
        }
    }

    reload() {
        this.$emit('reload');
    }
}
</script>
