import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VSlideYTransition,[(_vm.canUseAutofill && !_vm.loading)?_c(VContainer,{staticClass:"elevation-1 rounded"},[_c(VRow,{staticClass:"blueish px-2 rounded"},[_c(VCol,{staticClass:"text-body-2 font-weight-medium d-flex align-end pb-0"},[_vm._v(" Enter a search phrase here, and by the time you arrive at the Video module it will have auto-selected videos ")]),(_vm.$vuetify.breakpoint.mdAndUp)?_c(VCol,{attrs:{"cols":"auto"}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c(VChip,{staticClass:"px-4",attrs:{"label":"","color":"intense-blue","text-color":"white","small":""}},[_vm._v(" Beta ")]):_vm._e()],1):_vm._e()],1),_c(VRow,{staticClass:"blueish px-2 rounded"},[_c(VCol,{staticClass:"pr-0"},[_c('a-form',{ref:"form",attrs:{"warn-dirty":false}},[_c('a-text-input',{attrs:{"hide-details":"","loading":_vm.isLoading,"autocomplete":"off","background-color":"white"},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.autoFill.apply(null, arguments)}},model:{value:(_vm.phrase),callback:function ($$v) {_vm.phrase=$$v},expression:"phrase"}})],1)],1),_c(VCol,{attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","fab":"","disabled":_vm.isLoading || !_vm.phrase,"loading":_vm.isSaving},on:{"click":_vm.autoFill}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("film")])],1)]}}],null,false,727118690)},[_c('span',[_vm._v("Fill videos")])])],1)],1),_c('accept-matches',{attrs:{"phrase":_vm.phrase,"limit":_vm.limit,"found":_vm.found},on:{"accept":_vm.proceed,"cancel":_vm.reset}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }