<template>
    <span>
        <v-btn v-if="isPlaying" color="primary" fab small @click="pause">
            <v-icon>pause</v-icon>
        </v-btn>
        <v-btn
            v-else
            color="primary"
            fab
            small
            :loading="isLoading"
            :disabled="!isEnabled"
            @click="play"
        >
            <v-icon>play</v-icon>
        </v-btn>
    </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from '@/utils/decorators';

const AudioPlayerChipProps = Vue.extend({
    name: 'AudioPlayerChip',
    props: {
        src: {
            type: String,
            default() {
                return '';
            }
        }
    }
});

@Component
export default class AudioPlayerChip extends AudioPlayerChipProps {
    audioElement: HTMLAudioElement | null = null;

    isReady = false;
    isPlaying = false;

    get isLoading() {
        return this.isEnabled && !this.isReady;
    }

    get isEnabled() {
        return this.src !== '';
    }

    mounted() {
        this.onSrcUpdate();
    }

    @Watch('src')
    onSrcUpdate() {
        // this will make the audioElement to be discarded available for garbage collection and
        // prevent playing in the background
        if (this.audioElement) {
            this.audioElement.pause();
            this.audioElement = null;
        }

        this.isReady = false;
        this.isPlaying = false;

        if (this.src) {
            this.audioElement = new Audio();

            this.bind();

            this.audioElement.src = this.src;
        }
    }

    beforeDestroy() {
        if (this.audioElement) {
            this.audioElement.pause();
            this.audioElement = null;
        }
    }

    bind() {
        if (this.audioElement) {
            this.audioElement.addEventListener('canplaythrough', () => {
                this.isReady = true;
            });

            this.audioElement.addEventListener('error', error => {
                this.$emit('error', error);
            });

            this.audioElement.addEventListener('play', () => {
                this.isPlaying = true;
            });

            this.audioElement.addEventListener('pause', () => {
                this.isPlaying = false;
            });

            this.audioElement.addEventListener('ended', () => {
                this.isPlaying = false;
            });
        }
    }

    pause() {
        if (this.audioElement) {
            this.audioElement.pause();
            this.isPlaying = false;
        }
    }

    play() {
        if (this.audioElement) {
            this.audioElement.play();
        }
    }
}
</script>
