<template>
    <validation-provider
        ref="provider"
        v-slot="{ errors, invalid, validated }"
        slim
        :rules="booleanRules"
        :name="fieldName"
        :vid="$attrs.vid"
    >
        <v-checkbox
            v-if="checkbox"
            :label="label"
            :error-messages="errors"
            :error="validated && invalid"
            :input-value="$attrs.value"
            v-bind="$attrs"
            v-on="$listeners"
            @change="sync"
        >
            <slot v-for="(_, slot) in $slots" :slot="slot" :name="slot"></slot>
        </v-checkbox>
        <v-switch
            v-else
            :label="label"
            :error-messages="errors"
            :error="validated && invalid"
            :input-value="$attrs.value"
            v-bind="$attrs"
            v-on="$listeners"
            @change="sync"
        >
            <slot v-for="(_, slot) in $slots" :slot="slot" :name="slot"></slot>
        </v-switch>
    </validation-provider>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { ValidationProvider } from 'vee-validate';

const AToggleProps = Vue.extend({
    name: 'AToggle',
    props: {
        label: {
            type: String,
            default() {
                return '';
            }
        },
        field: {
            type: String,
            default() {
                return '';
            }
        },
        name: {
            type: String,
            default() {
                return '';
            }
        },
        checkbox: {
            type: Boolean,
            default() {
                return false;
            }
        },
        rules: {
            type: [String, Object],
            default() {
                return '';
            }
        }
    }
});

@Component({
    inheritAttrs: false,
    components: {
        ValidationProvider
    }
})
export default class AToggle extends AToggleProps {
    $refs!: {
        provider: InstanceType<typeof ValidationProvider>;
    };

    get fieldName() {
        return this.field || this.label || this.name;
    }

    get booleanRules() {
        return this.required
            ? {
                  required: {
                      allowFalse: false
                  }
              }
            : this.rules;
    }

    get required() {
        return typeof this.rules === 'string'
            ? this.rules?.includes('required')
            : Object.keys(this.rules).includes('required');
    }

    sync(value: boolean) {
        value = value || false;

        this.$emit('input', value);

        this.$refs.provider.setFlags({
            dirty: true,
            changed: true,
            touched: true
        });

        this.$refs.provider.syncValue(value);
    }
}
</script>

<style lang="scss" scoped>
.v-input::v-deep .v-label {
    color: $secondary-color;
}
</style>
