<template>
    <validation-provider
        ref="provider"
        v-slot="{ errors, invalid, validated }"
        slim
        :rules="rules"
        :name="fieldName"
        :vid="$attrs.vid"
    >
        <v-combobox
            filled
            :error-messages="observed ? [] : errors"
            :error="validated && invalid"
            :required="required"
            :counter="counterText"
            :counter-value="count"
            :class="{
                'input-disabled': hasReachedMax
            }"
            :hint="hint"
            v-bind="attrs"
            v-on="$listeners"
        />
    </validation-provider>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { ValidationProvider } from 'vee-validate';

const AComboboxProps = Vue.extend({
    name: 'ACombobox',
    props: {
        label: {
            type: String,
            default() {
                return '';
            }
        },
        field: {
            type: String,
            default() {
                return '';
            }
        },
        name: {
            type: String,
            default() {
                return '';
            }
        },
        rules: {
            type: [String, Object],
            default() {
                return '';
            }
        },
        observed: {
            type: Boolean,
            default() {
                return false;
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        },
        limited: {
            type: Boolean,
            default() {
                return false;
            }
        },
        limitedHint: {
            type: String,
            default() {
                return '';
            }
        },
        counter: {
            type: [String, Boolean, Number],
            default: false // eslint-disable-line quote-props
        },
        counterValue: {
            type: Function,
            default: null // eslint-disable-line quote-props
        }
    }
});

@Component({
    inheritAttrs: false,
    components: {
        ValidationProvider
    }
})
export default class ACombobox extends AComboboxProps {
    $refs!: {
        provider: InstanceType<typeof ValidationProvider>;
    };

    get attrs() {
        return {
            label: this.labelAltered,
            disabled: this.disabled,
            ...this.$attrs
        };
    }

    get labelAltered() {
        return `${this.label}${this.required && !this.disabled ? ' *' : ''}`;
    }

    get fieldName() {
        return this.field || this.label || this.name;
    }

    get required() {
        return typeof this.rules === 'string'
            ? this.rules?.includes('required')
            : Object.keys(this.rules).includes('required');
    }

    get max() {
        return (
            (typeof this.rules === 'string'
                ? this.rules
                      .split('|')
                      .find(rule => rule.split(':')[0] === 'max_items')
                      ?.split(':')[1]
                : this.rules.max_items) || void 0
        );
    }

    get counterText() {
        return this.counter || Boolean(this.counterValue) || this.max || false;
    }

    get hasReachedMax() {
        return this.limited && this.$attrs.value && this.max
            ? this.$attrs.value.length >= Number(this.max)
            : false;
    }

    get hint() {
        return this.hasReachedMax ? this.limitedHint : this.$attrs.hint;
    }

    count(value?: string) {
        if (typeof this.counterValue === 'function') {
            return this.counterValue(value);
        }

        return value ? value.length : 0;
    }
}
</script>

<style lang="scss" scoped>
.v-select--chips::v-deep {
    &.v-select.v-text-field--enclosed:not(.v-text-field--single-line):not(
            .v-text-field--outlined
        )
        .v-select__selections {
        padding-top: 24px;
        margin-left: -4px;

        .v-chip.v-size--small {
            padding: 0 8px;
        }
    }

    &.input-disabled {
        input {
            visibility: hidden;
            pointer-events: none;
        }
    }

    .v-input__slot:hover {
        .v-chip {
            background-color: $seashell-solid;
        }
    }
}
</style>
