<template>
    <modal :id="id" :title="title">
        <template #default>
            <p>
                Insufficient search results are available for your search term.
            </p>
            <p v-show="searchHasResults">
                If you proceed, available results will be used and remaining
                media slots for the video module will be populated with client
                images.
            </p>
            <p>
                You may instead cancel this search and try a different phrase.
            </p>
            <p class="text-caption text--secondary">
                {{ found }} results are available for term
                <strong>{{ phrase }}</strong>
                while {{ limit }} are required to fill the Video module.
            </p>
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="close">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                v-if="searchHasResults"
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                :disabled="!searchHasResults"
                @click="accept"
            >
                Proceed
            </v-btn>
            <v-btn
                v-else
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                @click="close"
            >
                Try another term
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { Modal } from '@/components/Modal';

const AcceptMatchesProps = Vue.extend({
    name: 'AcceptMatches',
    props: {
        phrase: {
            type: String,
            default() {
                return '';
            }
        },
        limit: {
            type: [String, Number],
            default() {
                return 0;
            }
        },
        found: {
            type: [String, Number],
            default() {
                return 0;
            }
        }
    }
});

@Component({
    components: {
        Modal
    }
})
export default class AcceptMatches extends AcceptMatchesProps {
    id = 'video-autofill-limited-matches';

    get title() {
        return this.searchHasResults
            ? 'Proceed with insufficient results'
            : 'Insufficient results';
    }

    get searchHasResults() {
        return Number(this.found) > 0;
    }

    accept() {
        this.$emit('accept');

        this.close();
    }

    close() {
        this.$emit('cancel');

        this.$store.dispatch('modal/close', this.id);
    }
}
</script>
