<template>
    <v-slide-y-transition>
        <v-container
            v-if="canUseAutofill && !loading"
            class="elevation-1 rounded"
        >
            <v-row class="blueish px-2 rounded">
                <v-col
                    class="text-body-2 font-weight-medium d-flex align-end pb-0"
                >
                    Enter a search phrase here, and by the time you arrive at
                    the Video module it will have auto-selected videos
                </v-col>
                <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="auto">
                    <v-chip
                        v-if="$vuetify.breakpoint.mdAndUp"
                        label
                        color="intense-blue"
                        text-color="white"
                        small
                        class="px-4"
                    >
                        Beta
                    </v-chip>
                </v-col>
            </v-row>
            <v-row class="blueish px-2 rounded">
                <v-col class="pr-0">
                    <a-form ref="form" :warn-dirty="false">
                        <a-text-input
                            v-model="phrase"
                            hide-details
                            :loading="isLoading"
                            autocomplete="off"
                            background-color="white"
                            @keypress.enter="autoFill"
                        />
                    </a-form>
                </v-col>
                <v-col cols="auto">
                    <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                fab
                                v-bind="attrs"
                                :disabled="isLoading || !phrase"
                                :loading="isSaving"
                                v-on="on"
                                @click="autoFill"
                            >
                                <v-icon>film</v-icon>
                            </v-btn>
                        </template>
                        <span>Fill videos</span>
                    </v-tooltip>
                </v-col>
            </v-row>
            <accept-matches
                :phrase="phrase"
                :limit="limit"
                :found="found"
                @accept="proceed"
                @cancel="reset"
            />
        </v-container>
    </v-slide-y-transition>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import { InjectReactive } from '@/utils/decorators';

import { AForm } from '@/components/AForm';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';

import AcceptMatches from './AcceptMatches.vue';

import type { AmpModules, Announcement } from '@/types/Announcement';

const VideoAutofillProps = Vue.extend({
    name: 'VideoAutofill',
    props: {
        modules: {
            type: Object as PropType<AmpModules | null>,
            default(): AmpModules | null {
                return null;
            }
        },
        limit: {
            type: [String, Number],
            default() {
                return 10;
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        AForm,
        ATextInput,
        AcceptMatches
    },
    computed: {
        ...mapGetters('user', ['isAuthor', 'isEditor'])
    }
})
export default class VideoAutofill extends VideoAutofillProps {
    $refs!: {
        form: InstanceType<typeof AForm>;
    };
    isAuthor!: boolean;
    isEditor!: boolean;

    endpoint = '/announcements/autofillVideo';

    phrase = '';

    found = 0;

    isSaving = false;

    @InjectReactive({
        from: 'announcement',
        default() {
            return {
                video_stock_media_autofill: null
            };
        }
    })
    announcement!: Partial<Announcement>;

    get announcementId() {
        return this.$route.params.announcementId;
    }

    get sourceUrl() {
        return [this.endpoint, this.announcementId].join('/');
    }

    get canUseAutofill() {
        return (this.isAuthor || this.isEditor) && !this.hasVideo;
    }

    get hasVideo() {
        return this.modules?.video_pr_id;
    }

    get isLoading() {
        return this.loading || this.isSaving;
    }

    autoFill() {
        if (this.$refs.form.isDirty) {
            return this.tryToAutofill();
        }
    }

    tryToAutofill() {
        return this.search().then(({ data }) => {
            if (data) {
                if (data.success) {
                    this.markWorking();
                } else {
                    this.found = data.found;

                    this.$store.dispatch(
                        'modal/open',
                        'video-autofill-limited-matches'
                    );
                }
            }
        });
    }

    search(proceed = false) {
        this.isSaving = true;

        return this.$http
            .get(this.sourceUrl, {
                params: {
                    keywords: this.phrase,
                    limit: this.limit,
                    proceed
                }
            })
            .then(({ data }) => data)
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }

                return {};
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

    proceed() {
        return this.search(true).finally(() => {
            this.markWorking();
        });
    }

    markWorking() {
        // mark autofill is in progress
        this.$set(this.announcement, 'video_stock_media_autofill', '[]');
        // reset input
        this.reset();
    }

    reset() {
        this.phrase = '';
        this.found = 0;
    }
}
</script>
