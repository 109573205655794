<template>
    <validation-provider
        ref="provider"
        v-slot="{ errors, invalid, validated }"
        slim
        :rules="rules"
        :name="fieldName"
        :vid="$attrs.vid"
    >
        <v-select
            filled
            :error-messages="observed ? [] : errors"
            :error="validated && invalid"
            :required="required"
            v-bind="attrs"
            v-on="$listeners"
        />
    </validation-provider>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { ValidationProvider } from 'vee-validate';

const ASelectInputProps = Vue.extend({
    name: 'ASelectInput',
    props: {
        label: {
            type: String,
            default() {
                return '';
            }
        },
        field: {
            type: String,
            default() {
                return '';
            }
        },
        name: {
            type: String,
            default() {
                return '';
            }
        },
        rules: {
            type: [String, Object],
            default() {
                return '';
            }
        },
        observed: {
            type: Boolean,
            default() {
                return false;
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    inheritAttrs: false,
    components: {
        ValidationProvider
    }
})
export default class ASelectInput extends ASelectInputProps {
    $refs!: {
        provider: InstanceType<typeof ValidationProvider>;
    };

    get attrs() {
        return {
            label: this.labelAltered,
            disabled: this.disabled,
            ...this.$attrs
        };
    }

    get labelAltered() {
        return `${this.label}${this.required && !this.disabled ? ' *' : ''}`;
    }

    get fieldName() {
        return this.field || this.label || this.name;
    }

    get required() {
        return typeof this.rules === 'string'
            ? this.rules?.includes('required')
            : Object.keys(this.rules).includes('required');
    }
}
</script>
